import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Component, OnInit } from '@angular/core';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-campaign-list',
  templateUrl: './all-campaign-list.component.html',
  styleUrl: './all-campaign-list.component.scss',
})
export class AllCampaignListComponent implements OnInit {
  public campaignList: any[] = [];

  constructor(
    private readonly campaignService: CampaignsService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getAllCampaigns();
  }

  private getAllCampaigns(): void {
    this.utilitiesService.showSpinner(true);
    this.campaignList = [];
    this.campaignService.getAllCampaigns(true).subscribe({
      next: (result: any) => {
        this.utilitiesService.showSpinner(false);
        this.campaignList = result;
        console.log(result);
      },
      error: (error: any) => {
        this.utilitiesService.showSpinner(false);
      },
    });
  }

  public navigateToDonation(campaign_id: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign_id,
    });
    this.router.navigate(['/donation'], {
      queryParams: { data: encryptedParams },
    });
  }
}
