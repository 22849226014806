<div class="search-body">
  <h2 class="title">Search result for “{{ ngoSearchQueryTitle }}”</h2>
  <div class="ngo-search-wrapper">
    <input
      class="ngo-search-input"
      type="text"
      [(ngModel)]="ngoSearchQuery"
      placeholder="Enter NGO name here"
      (keyup.enter)="onSearchClick()"
    />
    <button class="blue-button-md" (click)="onSearchClick()">Search</button>
  </div>
  <div class="result-no">{{ ngoList.length || "No" }} results found</div>

  <div *ngIf="ngoList?.length" class="ngo-list">
    <div class="list-title">NGO's</div>
    <div class="ngo-card" *ngFor="let ngo of ngoList">
      <div class="ngo-logo">
        <img [src]="ngo?.ngo_logo || './assets/group-39512.svg'" alt="logo" />
      </div>
      <div class="ngo-content">
        <div class="ngo-title">{{ ngo?.name }}</div>
        <div class="ngo-info">
          <div class="ngo-address">
            <img src="../../../../../assets/map-pin.svg" alt="pin" />
            <span>{{ ngo?.address }}</span>
          </div>
          <div class="ngo-website">
            <img src="../../../../../assets/globe.svg" alt="globe" />
            <span>{{ ngo?.website || "website.net" }}</span>
          </div>
        </div>
        <div class="ngo-description">
          {{
            ngo?.remarks ||
              "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam sapiente, similique quas necessitatibus consequatur, qui aspernatur veritatis incidunt ea rem repudiandae ullam esse maxime?"
          }}
        </div>
        <div class="ngo-action-buttons">
          <button class="blue-button-md" (click)="goToDetailsPage(ngo)">
            View Details
          </button>
          <!-- <button class="yellow-button-md">Donate</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
