<div id="hero" class="root">
  <div class="root-child">
    <div class="container-wrapper">
      <div class="container">
        <div class="frame-parent">
          <div class="startmanage-your-ngo-with-sho-wrapper">
            <div class="startmanage-your-ngo">
              {{ "Hero.Start/Upgrade" | translate }}
            </div>
          </div>
          <h1 class="shohay-the-all-in-one-container">
            <span>{{ "Hero.Shohay-:" | translate }} </span>
            <span class="the-all-in-one-ngo">{{
              "Hero.the-all-in" | translate
            }}</span>
          </h1>
          <div class="welcome-to-our">
            {{ "Hero.welcome-to" | translate }}
          </div>
          <div class="column_div">
            <div class="left_column">
              <div class="column_header">
                {{ "Hero.For NGOs/Non-Profits" | translate }}
              </div>
              <div class="column_items">
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/inventory.svg"
                    />
                  </div>
                  <div>{{ "Hero.Fundraising Solutions" | translate }}</div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/operational_efficiency_icon.svg"
                    />
                  </div>
                  <div>{{ "Hero.Operational Efficiency" | translate }}</div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/monitoring_icon.svg"
                    />
                  </div>
                  <div>
                    {{ "Hero.Monitoring & Reporting Assistance" | translate }}
                  </div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/knowledge_icon.svg"
                    />
                  </div>
                  <div>
                    {{ "Hero.Knowledge & Capacity Growth" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="right_column">
              <div class="column_header">
                {{ "Hero.For Donors/Agencies" | translate }}
              </div>
              <div class="column_items">
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/inventory.svg"
                    />
                  </div>
                  <div>
                    {{
                      "Hero.Identify and Support High-Impact NGOs" | translate
                    }}
                  </div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/security_icon.svg"
                    />
                  </div>
                  <div>
                    {{
                      "Hero.Ensure Transparency and Accountability" | translate
                    }}
                  </div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/process_icon.svg"
                    />
                  </div>
                  <div>
                    {{ "Hero.Streamline Your Giving Process" | translate }}
                  </div>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <div>
                    <img
                      class="inventory-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/project_management_icon.svg"
                    />
                  </div>
                  <div>
                    {{
                      "Hero.Simplify Project Monitoring and Evaluation"
                        | translate
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="feature-grid">
            <div class="feature-icons">
              <img
                class="inventory-icon"
                loading="lazy"
                alt=""
                src="../../../../../assets/images/icon/inventory.svg"
              />
  
              <div class="ngo-formation">
                {{ "Hero.ngo-formation" | translate }}
              </div>
            </div>
            <div class="feature-icons1">
              <img
                class="crowdsource-icon"
                loading="lazy"
                alt=""
                src=".././../../../../assets/images/icon/crowdsource.svg"
              />
  
              <div class="fundraising-donor">
                {{ "Hero.fundraising-donor" | translate }}
              </div>
            </div>
            <div class="feature-icons2">
              <img
                class="description-icon"
                loading="lazy"
                alt=""
                src="../../../../../../assets/images/icon/description.svg"
              />
  
              <div class="field-data-collection">
                {{ "Hero.field-data" | translate }}
              </div>
            </div>
            <div class="feature-icons3">
              <img
                class="add-task-icon"
                loading="lazy"
                alt=""
                src="../../../../../../assets/images/icon/add-task.svg"
              />
  
              <div class="project-planning">
                {{ "Hero.project-planning" | translate }}
              </div>
            </div>
          </div> -->
        </div>
        
      </div>
    </div>

    <div class="featured-container-parent">
      <div class="featured-container">
        <!-- <div class="featured-container-child"></div> -->
        <button class="featured-heading">
          <b class="featured">{{ "Hero.featured" | translate }}</b>
        </button>
        <div class="featured-n-g-os">
          <div class="n-g-o-card">
            <div class="card-wrapper">
              <div class="card-content">
                <!-- loading="lazy" -->
                <img
                  alt=""
                  class="gpt-4o-video-card-1-icon"
                  [ngSrc]="
                    '../../../../../assets/images/home_page/hp-1/gpt4ovideo-card-1@2x.png'
                  "
                  width="100"
                  height="100"
                  priority
                />

                <div class="n-g-o-logo">
                  <img
                    class="brac-logo-1-icon"
                    loading="lazy"
                    alt=""
                    [ngSrc]="
                      '../../../../../assets/images/logo/BRAC_logo 2.svg'
                    "
                    width="1"
                    height="1"
                  />
                </div>
              </div>
            </div>
            <div class="n-g-o-details">
              <div class="n-g-o-info">
                <h3 class="brac">{{ "Hero.brac" | translate }}</h3>
                <!-- <div class="donate-button">
                <button class="blue-button-sm">
                  {{ "Hero.Donate" | translate }}
                </button>
              </div> -->
              </div>
              <div class="brac-is-an">
                {{ "Hero.brac-is-an" | translate }}
              </div>
              <div class="learn-more cursor" (click)="goToNgoPage()">
                <div class="link-wrapper">
                  <a class="learn-more1">{{
                    "Hero.learn-more1" | translate
                  }}</a>
                </div>
                <img
                  class="chevron-forward-icon"
                  loading="lazy"
                  alt=""
                  src="../../../../../assets/images/icon/chevron-forward.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="submission-container-parent">
      <div class="submission-container">
        <div class="submission-message">
          <div class="submit-your-ngo">
            {{ "Hero.submit-your" | translate }}
          </div>
        </div>
        <img
          class="arrow-right-alt-icon"
          loading="lazy"
          alt=""
          src="../../../../../assets/images/icon/arrow-right-alt.svg"
        />
      </div>
      <div class="carousel">
        <div class="carousel-controls">
          <img
            class="arrow-forward-ios-icon"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/arrow-forward-ios@2x.png"
          />

          <div class="carousel-dots">
            <div class="dots-container">
              <div class="navigation-dots"></div>
              <div class="navigation-dots1"></div>
              <div class="navigation-dots2"></div>
            </div>
          </div>
          <img
            class="arrow-forward-ios-icon1"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/arrow-forward-ios-1.svg"
          />
        </div>
      </div>
    </div> -->
    </div>
  </div>
</div>
