import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-hp-new-camping-wall',
  templateUrl: './hp-new-camping-wall.component.html',
  styleUrl: './hp-new-camping-wall.component.scss',
})
export class HpNewCampingWallComponent {
  public comment_reply: any[] = [];
  public displayedComments: any[] = [];

  public imgsrc = '../../../../assets/images/winter_help.png';

  constructor(
    private readonly donationService: DonationService,
    public readonly utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.getComments();
  }

  public getComments() {
    this.donationService.getDonationWallComment().subscribe({
      next: (result) => {
        if (result.length > 0) {
          this.comment_reply = result.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());;
        }
      },
    });
  }

  get commentsFiltering(): any[] {
    return (this.displayedComments = this.comment_reply.slice(0, 3));
  }
}
