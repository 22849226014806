import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-hp-campaign',
  templateUrl: './hp-campaign.component.html',
  styleUrl: './hp-campaign.component.scss',
})
export class HpCampaignComponent {
  public campaignList: any[] = [];

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getAllCampaigns();
  }

  private getAllCampaigns(): void {
    this.spinner.show();
    this.ngoCampaignService.getAllNgoDonationCampaign(1, 3).subscribe({
      next: (result: any) => {
        this.spinner.hide();
        this.onSuccess(result);
      },
      error: (error: any) => {
        this.onError(`${error?.error?.message}`, `${error.name}`);
      },
    });
  }

  private onSuccess(res: any) {
    this.spinner.hide();
    this.campaignList = res.data;
  }

  private onError(errorMessage: string, title: string): void {
    this.spinner.hide();
    this.utilitiesService.showSwalWithToast(
      `${title}`,
      `${errorMessage} , Something went wrong `,
      'error'
    );
  }

  public calculateDaysLeft(endDate: string): number {
    const end = new Date(endDate).getTime();
    const now = new Date().getTime();
    if (end >= now) {
      return Math.ceil((end - now) / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }

  public navigateToCampaign(article: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      ngo_id: article?.ngo_id,
      project_id: article?.project_id,
      id: article?.id,
    });
    this.router.navigate(['/campaign'], {
      queryParams: { data: encryptedParams },
    });
  }

  public navigateToCampaignDonation(campaign: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/donate-to-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }
}
