import { Component, OnInit } from '@angular/core';
import { CallBookingModel } from 'src/app/shared/model/call-booking.model';
import {
  ContactTypeEnum,
  NgoStatusEnum,
} from 'src/app/shared/enum/call-booking.enum';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { ContactRequestService } from 'src/app/shared/services/contact-request/contact-request.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-hp9-contact-form',
  templateUrl: './hp9-contact-form.component.html',
  styleUrls: ['./hp9-contact-form.component.scss'],
})
export class Hp9ContactFormComponent implements OnInit {
  public today: Date = new Date();
  public contactType = ContactTypeEnum;
  public ngoStatus = NgoStatusEnum;
  public contactForm: CallBookingModel = new CallBookingModel();

  public ngoSlide: any = [
    {
      company_logo: '../../../assets/images/home_page/hp-7/logo-1@2x.png',
      description:
        "Shohay's expertise enabled successful inception of our clean water initiative. They were a genuine partner in catalyzing transformative chang",
      image: '../../../assets/images/home_page/hp-8/ellipse-19-1@2x.png',
      representative_name: 'NGO Representative Name',
      title: 'Position or Title',
    },
    {
      company_logo: '../../../assets/images/home_page/hp-7/image-1@2x.png',
      description:
        "Shohay's expertise enabled successful inception of our clean water initiative. They were a genuine partner in catalyzing transformative chang",
      image: '../../../assets/images/home_page/hp-8/ellipse-19-2@2x.png',
      representative_name: 'NGO Representative Name',
      title: 'Position or Title',
    },
    {
      company_logo: '../../../assets/images/home_page/hp-7/image-8@2x.png',
      description:
        "Shohay's expertise enabled successful inception of our clean water initiative. They were a genuine partner in catalyzing transformative chang",
      image: '../../../assets/images/home_page/hp-8/ellipse-19@2x.png',
      representative_name: 'NGO Representative Name',
      title: 'Position or Title',
    },
  ];
  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly contactRequestService: ContactRequestService
  ) {}

  ngOnInit(): void {}

  public changeContactType(contactType: ContactTypeEnum): void {
    this.contactForm.contact_type = contactType;
  }

  public async submit() {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      const response = await this.utilitiesService.showSwalAndReturn({
        type: 'warning',
        title: `Are you sure?`,
        message: 'Do You want shohay team contact with you?',
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });
      if (response.isConfirmed) {
        this.utilitiesService.showSpinner(true);
        this.contactRequestService.bookACall(this.contactForm).subscribe({
          next: (result: any) => {
            this.utilitiesService.showSpinner(false);
            this.utilitiesService.showSwalWithToast(
              'Success',
              `${
                this.contactForm.contact_type === this.contactType.CALL
                  ? 'Your call is booked'
                  : 'Message send'
              } successfully.`
            );
            ValidationEngine.resetGroup('validationGrp');
          },
          error: (error: any) => {
            this.utilitiesService.showSpinner(false);
            this.utilitiesService.showSwalWithToast(
              error.error.error_name,
              error.error.message,
              'error'
            );
          },
        });
      }
    }
  }
}
