<div class="main_div">
  <div>
    <span style="color: #0054a6">Home > </span
    ><span>{{ campaign?.appeal_subtitle }}</span>
  </div>
  <div class="row mt-32">
    <div class="col-md-6" style="font-size: 18px">
      <div
        class="img_slider"
        [ngStyle]="{ 'background-image': backgroundImage }"
      >
        <div class="yellow_title_div">
          <div class="yellow_title">{{ campaign?.appeal_subtitle }}</div>
        </div>
      </div>

      <div class="title">{{ campaign?.title }}</div>
      <div [innerText]="campaign?.description"></div>
      <p class="mt-32">
        For queries and support contact
        <a class="a">support&#64;shohay.net</a>
      </p>
    </div>

    <div class="col-md-6">
      <div class="form_div_parent d-flex justify-content-center">
        <div class="form_div d-flex flex-column">
          <div class="form_header">Donation Form</div>
          <div class="form_text text-center">
            Please complete the donation form, and our team will get in touch
            with you shortly.
          </div>

          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Your Name</label>
            <dx-text-box
              class="myTextBox"
              [(ngModel)]="donationModel.name"
              placeholder="Enter your name here"
              [showClearButton]="true"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Your name is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Email</label>
            <dx-text-box
              class="myTextBox"
              [(ngModel)]="donationModel.email"
              placeholder="Enter your email address here"
              [showClearButton]="true"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Your email is required"
                ></dxi-validation-rule>
                <dxi-validation-rule
                  type="email"
                  message="Email is invalid. Ex: jone@gmail.com"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Phone</label>
            <dx-text-box
              class="myTextBox"
              [(ngModel)]="donationModel.phone"
              placeholder="Enter phone number here"
              [showClearButton]="true"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Phone is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="d-flex flex-column" style="gap: 24px">
            <label class="field_label requiredMark">Donation Amount</label>
            <dx-number-box
              class="myTextBox"
              [(ngModel)]="donationModel.amount"
              placeholder="Enter your donation amount here"
              [showClearButton]="true"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Donation Amount is required"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </div>

          <div>
            <button
              (click)="onSubmit()"
              class="blue-button-lg w-100"
              style="margin-bottom: 16px"
            >
              Submit
            </button>

            <div class="form_text text-center">
              By proceeding further you agree to our <a class="terms_link"
                >Terms and conditions
              </a>
              and <a class="terms_link">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="center_div" style="background-color: #fffbe5">
  <div class="title_div">
    <p class="p_4">How it works</p>
    <p class="p_3">Steps to Make a Difference with Shohay</p>
  </div>

  <div class="row" style="margin-top: 50px">
    <div class="col col_center">
      <img
        style="width: 100px"
        src="./assets/images/icon/donation_colorful_icon.svg"
      />
      <div class="donation_step_title">Donation Payment</div>
      <div
        style="text-align: center"
        [innerHTML]="
          'Bangladesh: Donations can be made directly via BKash or otherECommerce channels.<br> <br> Abroad: We will try to connect you to a local approved Non-Profit organization, which will route your payment to a vetted NGO in Bangladesh'
        "
      ></div>
    </div>
    <div class="col col_center">
      <img style="width: 100px" src="./assets/images/icon/receipt_icon.svg" />
      <div class="donation_step_title">Receipt and Verification</div>
      <div
        style="text-align: center"
        [innerHTML]="
          'Shohay will provide you with a receipt for your donation. You will also receive a picture showing your donation being given to the recipient.'
        "
      ></div>
    </div>
    <div class="col col_center">
      <img
        style="width: 100px"
        src="./assets/images/icon/online_meeting_icon.svg"
      />
      <div class="donation_step_title">
        Additional Engagement (For Larger Donations)
      </div>
      <div
        style="text-align: center"
        [innerHTML]="
          'We can arrange a WhatsApp or Zoom call with the recipient, so that you can interact with the recipient first-hand. We may need to charge an additional fee if the donation amount falls below the minimum amount required for this service.<br> <br> You will receive a recording of this interaction, which you can share on social media to inspire others to contribute to these causes.'
        "
      ></div>
    </div>
  </div>
</div>

<div class="center_div pt_48" style="padding-right: 0px;">
  <p class="p_4">Champions of Giving</p>
  <p class="p_3">Acknowledging Generosity</p>
  <div class="row" style="width: -webkit-fill-available; margin-top: 50px">
    <div class="col donor_row" style="margin-bottom: 32px">
      <div class="donor_card">
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/round_profile.png"
          />
          <div class="donor_card_details">
            <span class="donor_name">Anonymous</span>
            <span>Fremont, CA, USA</span>
            <span class="donor_amount">$100</span>
            <span>Aug 25, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
      <div class="donor_card">
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/mobashwir_bhai.png"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Mobashwir Hasan</span>
            <span>Dhaka, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 25, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
      <div class="donor_card">
        <img
          class="donor_card_cover_img"
          src="./assets/images/wounded_student_2.png"
        />
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/mobashwir_bhai.png"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Mobashwir Hasan</span>
            <span>Dhaka, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 25, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col donor_row" style="margin-bottom: 32px">
      <div class="donor_card">
        <img
          class="donor_card_cover_img"
          src="./assets/images/wounded_student_4.png"
        />
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/wahidul_bhai.jpeg"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Wahidul Islam</span>
            <span>Chittagong, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 26, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
      <div class="donor_card">
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/round_profile.png"
          />
          <div class="donor_card_details">
            <span class="donor_name">Anonymous</span>
            <span>Fremont, CA, USA</span>
            <span class="donor_amount">$100</span>
            <span>Aug 25, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
      <div class="donor_card">
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/wahidul_bhai.jpeg"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Wahidul Islam</span>
            <span>Chittagong, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 26, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col donor_row">
      <div class="donor_card">
        <img
          class="donor_card_cover_img"
          src="./assets/images/wounded_student_5.png"
        />
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/wahidul_bhai.jpeg"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Wahidul Islam</span>
            <span>Chittagong, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 26, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
      <div class="donor_card">
        <div class="donor_card_div">
          <img
            class="donor_card_profile_img"
            src="./assets/images/mobashwir_bhai.png"
          />
          <div class="donor_card_details">
            <span class="donor_name">Md. Mobashwir Hasan</span>
            <span>Dhaka, Bangladesh</span>
            <span class="donor_amount">৳10,000</span>
            <span>Aug 25, 2024</span>
          </div>
        </div>
        <div class="donor_card_bottom_div">
          <div class="donor_card_button">
            <img src="./assets/images/icon/love_icon.svg" /> <span>Love</span>
          </div>
          <div class="donor_card_button">
            <img src="./assets/images/icon/share_icon.svg" /><span>Share</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="center_div mt-0 pt-0">
  <button class="blue-button-lg">More Champions</button>
  <div class="center_div mt-0">
    <p class="p_4">FAQ</p>
    <p class="p_3">Frequently Asked Question and Answer</p>

    <div style="margin-top: 50px">
      <div class="questions">
        <span>Why should i support SHOHAY?</span>
        <img
          title="Read More"
          style="cursor: pointer"
          src="./assets/images/icon/add_circle_black_icon.svg"
        />
      </div>
      <div class="questions">
        <span>How do i know that my money will go to the right people?</span>
        <img
          title="Read More"
          style="cursor: pointer"
          src="./assets/images/icon/add_circle_black_icon.svg"
        />
      </div>
      <div class="questions">
        <span>
          Can SHOHAY send me a photo of the recipient I am giving relief to?
        </span>
        <img
          title="Read More"
          style="cursor: pointer"
          src="./assets/images/icon/add_circle_black_icon.svg"
        />
      </div>
      <div class="questions">
        <span>Why is SHOHAY appealing for money?</span>
        <img
          title="Read More"
          style="cursor: pointer"
          src="./assets/images/icon/add_circle_black_icon.svg"
        />
      </div>
    </div>
  </div>
</div>
