<div class="main_div">
  <div class="title_full_div">
    <div class="title_div">
      <div class="title">About Us</div>
      <div class="sub_title">
        We are here to assist you with any inquiries or support you may need.
        Please use the form below to get in touch with us.
      </div>
    </div>
  </div>

  <div class="white_div_container">
    <div class="white_div">
      <div class="highlighted_div">
        <div class="highlighted_text_div">
          <div class="highlighted_title">
            A centralized digital platform connecting NGOs with donors to
            amplify social impact.
          </div>
          <div class="highlighted_text">
            Shohay is a centralized digital platform dedicated to empowering
            NGOs and non-profit organizations by connecting them with donors,
            streamlining operations, and amplifying their social impact. Our
            mission is to enhance transparency, efficiency, and impact across
            the non-profit sector by providing operational visibility and
            tracking the effectiveness of donations to foster positive change.
          </div>
          <div class="sign_up_button">
            <button class="blue-button-lg" routerLink="/auth/signup">
              Sign Up Free
            </button>
          </div>
        </div>
        <div class="highlighted_image_div">
          <img
            class="highlighted_image"
            alt="help people"
            loading="lazy"
            src="../../../../assets/images/help_people.png"
          />
        </div>
      </div>

      <div class="why_us_div">
        <div class="why_us_title_div">
          <div class="why_us_title">Why choose us</div>
          <div class="why_us_subtitle">
            Discover inspiring stories, expert insights, and the latest news
            from Shohay.
          </div>
        </div>
        <div class="reason_card_div">
          <div class="reason_card">
            <div class="reason_card_image_div">
              <img
                class="reason_card_image"
                loading="lazy"
                src="../../../../assets/images/help_campaign_picture.png"
              />
            </div>
            <div class="reason_card_text_div">
              <div class="text_title">For Donors</div>
              <div class="reason_text">
                Shohay offers an affordable, user-friendly platform that
                provides essential information about NGOs, their projects, and
                the impact they make. This transparency helps donors make
                informed decisions and see the real-world effects of their
                contributions.
              </div>
            </div>
          </div>

          <div class="reason_card">
            <div class="reason_card_image_div">
              <img
                class="reason_card_image"
                loading="lazy"
                src="../../../../assets/images/non_profit_picture.png"
              />
            </div>
            <div class="reason_card_text_div">
              <div class="text_title">For Non-profits</div>
              <div class="reason_text">
                Shohay eliminates the need for costly, fragmented systems and
                simplifies the management of multiple funding channels. We help
                local NGOs connect with individual donors, institutional donors,
                and philanthropic foundations. Our platform provides real-time
                data and performance insights, empowering both donors and NGOs
                to achieve their goals effectively.
              </div>
            </div>
          </div>

          <div class="reason_card">
            <div class="reason_card_image_div">
              <img
                class="reason_card_image"
                loading="lazy"
                src="../../../../assets/images/global_operation_picture.png"
              />
            </div>
            <div class="reason_card_text_div">
              <div class="text_title">Global Operations</div>
              <div class="reason_text">
                Shohay operates globally, with headquarters in Silicon Valley
                and operations in Bangladesh. Our leadership team brings
                extensive experience in developing solutions for major NGOs
                worldwide, from creating systems for the United Way in the U.S.
                to supporting the largest NGO in Bangladesh. Leveraging this
                expertise, we offer scalable solutions—from accessible entry
                points for smaller non-profits to complex, turnkey systems for
                large implementations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
