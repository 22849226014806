<div class="main_div">
  <div class="title_full_div">
    <div class="title_div">
      <div class="title">Contact Us</div>
      <div class="sub_title">
        We are here to assist you with any inquiries or support you may need.
        Please use the form below to get in touch with us.
      </div>
    </div>
  </div>
  <div class="white_div_container">
    <div class="white_div">
      <div class="full_container">
        <div class="Location">
          <div class="contact-item">
            <div class="icon">
              <img
                height="24px"
                width="24px"
                alt="help people"
                loading="lazy"
                src="../../../../assets/images/icon/map-pin.svg"
              />
            </div>
            <div class="text_div">
              <div class="header_text">Our Location</div>
              <div>
                <strong>Bangladesh:</strong> 53, Purana Paltan (2nd Floor)
                <br />
                Dhaka-1000, Bangladesh <br />
                <div class="mt-1">
                  <strong>USA:</strong> 2382 Night Shade Ln<br />
                  Fremont, CA 94539
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="Email">
          <div class="contact-item">
            <div class="icon">
              <img
                class="highlighted_image"
                alt="help people"
                loading="lazy"
                src="../../../../assets/images/icon/mail.svg"
              />
            </div>
            <div class="text_div">
              <div class="header_text">Email Address</div>
              <div>
                <strong>Support:</strong> : support&#64;shohay.net
                <br />
                <div class="mt-1">
                  <strong>Sales: </strong> sales&#64;shohay.net
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="phone">
          <div class="contact-item">
            <div class="icon">
              <img
                class="highlighted_image"
                alt="help people"
                loading="lazy"
                src="../../../../assets/images/icon/phone.svg"
              />
            </div>
            <div class="text_div">
              <div class="header_text">Phone Number</div>
              <div>
                <strong>Bangladesh Office:</strong> +880 1911614074 | Sunday - Thursday<br />
                <div class="mt-1">
                  <strong>US Office: :</strong> +510 456-0574 | Monday - Friday
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="input_full_div">
        <div class="map_div">
          <div id="map" style="min-height: 750px; overflow: hidden"></div>
        </div>
        <div class="input_msg_div">
          <div class="ngos">
            <div class="buttons">
              <button
                [ngClass]="
                  contactForm.contact_type === contactType.MESSAGE
                    ? 'blue-button-rounded-md'
                    : 'white-button-rounded-md'
                "
                (click)="changeContactType(contactType.MESSAGE)"
              >
                {{ "Contact-form.Message" | translate }}
              </button>
              <button
                [ngClass]="
                  contactForm.contact_type === contactType.CALL
                    ? 'blue-button-rounded-md'
                    : 'white-button-rounded-md'
                "
                (click)="changeContactType(contactType.CALL)"
              >
                {{ "Contact-form.Book" | translate }}
              </button>
            </div>

            <div class="form_div">
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.Name" | translate
                }}</label>
                <dx-text-box
                  class="input_field"
                  [(ngModel)]="contactForm.name"
                  [placeholder]="'Contact-form.placeHolder1' | translate"
                  [showClearButton]="true"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Name is required"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.Email" | translate
                }}</label>
                <dx-text-box
                  class="input_field"
                  [(ngModel)]="contactForm.email"
                  [placeholder]="'Contact-form.placeHolder2' | translate"
                  [showClearButton]="true"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Email is required"
                    ></dxi-validation-rule>
                    <dxi-validation-rule
                      type="email"
                      message="Invalid email. e.g. john@gmail.com"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="input_div">
                <label class="field_label">{{
                  "Contact-form.Organization" | translate
                }}</label>
                <dx-text-box
                  class="input_field"
                  [(ngModel)]="contactForm.organization"
                  [placeholder]="'Contact-form.placeHolder4' | translate"
                  [showClearButton]="true"
                >
                  <dx-validator validationGroup="validationGrp"> </dx-validator>
                </dx-text-box>
              </div>
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.Messages" | translate
                }}</label>
                <dx-text-box
                  class="input_field"
                  [(ngModel)]="contactForm.message"
                  [placeholder]="'Contact-form.placeHolder5' | translate"
                  [showClearButton]="true"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Message is required"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              @if(contactForm.contact_type === contactType.CALL) {
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.Phone" | translate
                }}</label>
                <dx-text-box
                  class="input_field"
                  [(ngModel)]="contactForm.phone"
                  [placeholder]="'Contact-form.placeHolder3' | translate"
                  [showClearButton]="true"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Phone number is required"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.DateTime" | translate
                }}</label>
                <dx-date-box
                  class="input_field"
                  [(ngModel)]="contactForm.date"
                  [placeholder]="'Contact-form.placeHolder6' | translate"
                  [showClearButton]="true"
                  type="datetime"
                  [min]="today"
                  displayFormat="longDateLongTime"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Date & Time is required"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
              }
              <div class="input_div">
                <label class="field_label requiredMark">{{
                  "Contact-form.select-one-of" | translate
                }}</label>
                <dx-radio-group
                  class="input_field radio_group_field"
                  [items]="[
                    {
                      name: 'Contact-form.Planning' | translate,
                      value: ngoStatus.PLANNING
                    },
                    {
                      name: 'Contact-form.already-have-an' | translate,
                      value: ngoStatus.HAVE
                    }
                  ]"
                  displayExpr="name"
                  valueExpr="value"
                  [(value)]="contactForm.ngo_status"
                  layout="vertical"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="You must select one."
                    ></dxi-validation-rule
                  ></dx-validator>
                </dx-radio-group>
              </div>
            </div>

            <button class="blue-button-md" (click)="submit()">
              {{ "Contact-form.Submit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
