export class NewsArticleBodyModel {
  id: number = null;
  news_article_id: string = null;
  reading_time: string = null;
  blog_type: string = null;
  topic: string = null;
  title: string = null;
  cover_photo: string = null;
  table_of_contents: any[] = null;
  tags: string[] = null;
  comments: CommentModel[] = null;
  createdAt: Date = null;
  publishedAt: Date = null;
  updatedAt: Date = null;
  author_id: string = null;
  author_name: string = null;
  content: any[] = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class CommentModel {
  id: number = null;
  comment_id: string = null;
  comment: string = null;
  user_id: string = null;
  user_name: string = null;
  createdAt: Date = null;
  publishedAt: Date = null;
  updatedAt: Date = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
