<!-- <div class="container-fluid"> -->
  <!-- row text-start -->
<div class="root1">
  <div class="transformation col-md-8 col-sm-12">
    <h1 class="transform-your-ngo-container">
      <p>
        {{ "Sign-up.transform-your" | translate }}
      </p>
    </h1>
    <div class="have-an-ngo">
      {{ "Sign-up.have-an-ngo" | translate }}
    </div>
  </div>

  <div class="call-to-action col-md-4 col-12 col-sm-12">
    <button class="yellow-button-md" [routerLink]="'/auth/signup'">
      {{ "Sign-up.Sign-Up" | translate }}
    </button>
    <div class="credit-card">
      <!-- <input class="check" type="checkbox" /> -->

      <div class="no-credit-card">{{ "Sign-up.no-credit" | translate }}</div>
    </div>
  </div>
</div>
<!-- </div> -->
