<div class="main_div">
  <div>
    <span style="color: #0054a6">Home > </span>
    <span>All relief captions</span>
  </div>

  <div class="card_div">
    <div *ngFor="let campaign of campaignList; let i = index" class="card">
      <div class="img_div">
        <img class="image" [src]="campaign?.appeal_img_url?.url" />
      </div>
      <div class="details_div">
        <div class="title">{{ campaign.appeal_subtitle }}</div>
        <div class="title name">{{ campaign.appeal_title }}</div>
        <div class="button_div">
          <div style="display: flex; gap: 16px">
            <button class="blue-button-md">View Details</button>
            <button
              class="yellow-button-md"
              (click)="navigateToDonation(campaign?.id)"
              >
              <!-- [routerLink]="['/donation']"
              [queryParams]="{ id: campaign?.id }" -->
              Donate Now
            </button>
          </div>
          <div>
            <img
              class="cursor"
              title="Share"
              src="./assets/images/icon/share_icon.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
