<div class="main_div">
  <div>
    <span style="color: #0054a6">Home > {{ donationSummery?.ngo_name }} ></span
    ><span> Donation</span>
  </div>
  <div class="main_container mt-4 mb-4">
    <div class="left_side">
      <div class="left_total_donation">
        <div class="page_title">Enter Donation Amount</div>
        <div class="suggested_all_amount p-2">
          <div
            class="amount"
            *ngFor="let fund of suggestedFund"
            (click)="selectedAmountFunc(fund.amount)"
          >
            ৳ {{ fund.amount | number }}
            <span class="suggested" *ngIf="fund.isSuggested">Suggested</span>
          </div>
        </div>
        <div class="total_amount">
          <div class="taka_sign">৳</div>
          <div class="taka_amount">
            <dx-number-box
              class="amount_tk"
              placeholder=""
              [(ngModel)]="donationInput"
              [invalidValueMessage]="'Value must be a number'"
              [format]="',##0'"
              [valueChangeEvent]="'input'"
            >
              <dx-validator validationGroup="validationGrp">
                <dxi-validation-rule
                  type="required"
                  message="Target amount is required"
                ></dxi-validation-rule>
                <dxi-validation-rule
                  type="range"
                  [min]="10"
                  message="Minimum amount 10 tk"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </div>
        </div>
      </div>

      <div class="donation_details">
        <div class="donation_amount_transaction_fees">
          <div class="donation_amount">
            <div class="donation_name">Donation</div>
            <div class="donation_number">
              ৳
              {{ getDonationAmount() | number }}
            </div>
          </div>
          <div class="transaction_fees">
            <div class="transaction_fees_amount">
              <div class="transaction_fees_title">Total transaction fees</div>
              <div class="transaction_amount">
                ৳
                {{ totalDonationModel.transaction_fees | number }}
              </div>
            </div>
            <div class="_100_of_your">
              100% of your donation goes to {{ donationSummery?.title }}
            </div>
          </div>
        </div>
        <div class="donation_inf">
          <div class="details_inf">
            Processing fees are always charged by companies like SSL, Bkash or
            Bank, no matter what platform you use. Shohay makes the Processing
            Fees 100% transparent and takes 0% of any amount.
          </div>
          <div class="confirmation">
            <p class="cursor">
              <dx-check-box
                [(value)]="transactionFeeStatus"
                [elementAttr]="{ 'aria-label': 'Checked' }"
                class="checkbox"
                text="I’ll cover the transaction fee"
              >
              </dx-check-box>
            </p>
          </div>
        </div>

        <div class="innet_pop_total_">
          <div class="donation_name">Total Amount</div>
          <div class="transaction_amount">
            ৳
            {{
              (transactionFeeStatus
                ? donationInput + totalDonationModel.transaction_fees
                : donationInput || 0
              ) | number
            }}
          </div>
        </div>
      </div>

      <div class="fundrising_member">
        <div class="fundrising_header">
          <span class="fundrising_title">Credit to Fundraiser </span>
          <div class="details_inf_">
            Empowering Communities Through Collective Giving.
          </div>
        </div>

        <div class="boxes">
          <dx-select-box
            class="custom-select-box"
            [dataSource]="fundRaiserTeams"
            [displayExpr]="'team_name'"
            placeholder="Select Fundraising Team"
            (onValueChanged)="onTeamSelected($event)"
            [value]="totalDonationModel?.team_id"
            [valueExpr]="'id'"
            [showClearButton]="true"
          >
            <div *dxTemplate="let item of 'item'">
              <div class="team-card">
                <img
                  [src]="
                    item.team_logo.url || 'assets/images/round_profile.png'
                  "
                  alt="Team Logo"
                  class="team-logo"
                />
                <span class="team-name">{{ item.team_name }}</span>
              </div>
            </div>
          </dx-select-box>
          <dx-text-box
            class="search_box"
            placeholder="Search Fundraiser"
            stylingMode="filled"
            [showClearButton]="true"
            (onValueChanged)="searchFundraisingMember($event)"
            [valueChangeEvent]="'input'"
          >
            <dxi-button
              name="searchIcon"
              location="after"
              [options]="searchIcon"
            ></dxi-button>
          </dx-text-box>
        </div>

        <div class="fund_member_div">
          <div class="fund_member_card">
            <div
              class="card"
              *ngFor="let member of teamMembers"
              (click)="selectFundraisingMember(member)"
              [ngClass]="{
                selected: totalDonationModel.fundraiser_id === member.id
              }"
            >
              <div class="img">
                <img
                  class="click"
                  alt="{{ member.fundraiser_name }}"
                  title="team logo"
                  [src]="
                    member.profile_img
                      ? member.profile_img.url
                      : 'assets/images/round_profile.png'
                  "
                />
              </div>
              <div class="image_name">
                {{ member.fundraiser_name }}
              </div>
            </div>
          </div>
        </div>

        <div class="donar_inf">
          <div class="donation_details_text">Donation Details</div>
          <div class="donor_comment">
            <div class="add_a_public">Add a public message.</div>
            <div class="donar_submit_form">
              <div class="form-group">
                <label class="field-label requiredMark">Name</label>
                <dx-text-box
                  class="myTextBox"
                  placeholder="Enter Your Name"
                  [showClearButton]="true"
                  [(ngModel)]="totalDonationModel.name"
                  [readOnly]="sessionUser"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Name is required."
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="form-group">
                <label class="field-label requiredMark">Email</label>
                <dx-text-box
                  class="myTextBox"
                  placeholder="Enter your email address here"
                  [showClearButton]="true"
                  [(ngModel)]="totalDonationModel.email"
                  [readOnly]="sessionUser"
                >
                  <dx-validator validationGroup="validationGrp">
                    <dxi-validation-rule
                      type="required"
                      message="Email is required."
                    ></dxi-validation-rule>
                    <dxi-validation-rule
                      type="email"
                      message="Email is invalid. Ex: jone@gmail.com"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
              <div class="form-group">
                <label class="field-label">Message</label>
                <dx-text-area
                  class="text_area_field"
                  placeholder="Write about messages here"
                  [(ngModel)]="totalDonationModel.comment"
                  (ngModelChange)="check()"
                >
                  <dx-validator validationGroup="validationGrp">
                    <!-- <dxi-validation-rule
                      type="required"
                      message="Write Messages"
                    >
                    </dxi-validation-rule> -->
                  </dx-validator>
                </dx-text-area>
              </div>
            </div>
          </div>
        </div>

        <div class="payment_section">
          <p class="cursor">
            <dx-check-box
              [(value)]="totalDonationModel.is_hidden_name"
              [elementAttr]="{ 'aria-label': 'Checked' }"
              class="checkbox"
              text="Hide name from everyone but the Shohay can view for legal purposes "
            >
            </dx-check-box>
          </p>
          <div class="whatsapp_checkbox">
            <p class="cursor">
              <dx-check-box
                [elementAttr]="{ 'aria-label': 'Checked' }"
                class="checkbox"
                text="Would like to have a video call with the recipients?"
                [(value)]="totalDonationModel.is_video_call_active"
              >
              </dx-check-box>
            </p>
            <div
              class="form-group mb-2"
              *ngIf="totalDonationModel.is_video_call_active"
            >
              <label class="field-label">Whatsapp<span>*</span></label>
              <dx-text-box
                class="myTextBox"
                placeholder="Enter your Whatsapp number"
                [showClearButton]="true"
                [(ngModel)]="totalDonationModel.whatsapp_no"
              >
                <dx-validator validationGroup="validationGrp">
                  <dxi-validation-rule
                    type="required"
                    message="Whatsapp number is required."
                  ></dxi-validation-rule>
                  <dxi-validation-rule
                    type="pattern"
                    pattern="^\+?[0-9]\d{1,14}$"
                    message="Only number allowed."
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="instructioin">
            <!-- [disabled]="totalDonationModel.comment === '' "
            [disabled]="totalDonationModel.comment === null " -->
            <dx-check-box
              [disabled]="!totalDonationModel.comment || totalDonationModel.comment.trim() === ''"
              [elementAttr]="{ 'aria-label': 'Checked' }"
              class="checkbox"
              text="Would like to featured your donation at Donor experience wall ?"
              [(value)]="totalDonationModel.is_featured_donation"
            >
            </dx-check-box>
            <dx-check-box
              [(value)]="totalDonationModel.can_contact"
              [elementAttr]="{ 'aria-label': 'Checked' }"
              class="checkbox no-wrap"
              [text]="'Allow ' + donationSummery?.ngo_name + ' to contact me'"
            >
            </dx-check-box>
            <dx-check-box
              [(value)]="totalDonationModel.is_hidden_name_and_amount"
              [elementAttr]="{ 'aria-label': 'Checked' }"
              class="checkbox"
              text="Hide my name and amount from Public view"
            >
            </dx-check-box>
          </div>
          <div class="donate_payment">
            <div class="pay_buttons">
              <div
                class="blue-button-lg fw-bold cursor w-100"
                (click)="onSubmit()"
              >
                Donate Now
              </div>
            </div>

            <div class="agreement_inf">
              By choosing the payment method above, you agree to the Shohay
              <span (click)="termsPopUp()" class="cursor">Terms of Service</span> and acknowledge the
              <span  (click)="privacyPopUp()" class="cursor">Privacy Notice</span> . Learn more about
              <span (click)="pricingPopUp()" class="cursor">pricing and fees.</span>
            </div>
            <div class="payment_method">
              <div class="payment_title">Payment Method</div>
              <div class="payment_way">
                <img
                  class="cursor logo"
                  routerLink="/"
                  src="../../../../../assets/american-express.jpg"
                />

                <img
                  class="cursor logo"
                  routerLink="/"
                  src="../../../../../assets/card.jpg"
                />

                <img
                  class="cursor logo"
                  routerLink="/"
                  src="../../../../../assets/visa.jpg"
                />

                <img
                  class="cursor logo"
                  routerLink="/"
                  src="../../../../../assets/BKash-bKash-Logo.wine 1.jpg"
                />
              </div>
              <div class="payment_way_name_ins">
                <div class="pay_way_name">Debit/Credit card / Bkash</div>
                <div class="pay_way_ins">
                  Safe payment with your Debit/Credit Card and Bkash account
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right_side">
      <div class="donation">
        <div class="donation_details_right d-flex flex-column gap-3">
          <div class="news-cards">
            <div class="cards">
              <div
                class="rectangle-parent"
                [style]="
                  'background-image: url(' +
                  donationSummery?.cover_img_vdo?.url +
                  ');'
                "
              >
                <div
                  *ngIf="donationSummery?.is_featured"
                  class="article-wrapper"
                >
                  <div class="article">Featured</div>
                </div>
              </div>
              <div class="development-ngos-arm">
                {{ donationSummery?.title }}
              </div>
              <div class="name_with_checkMark">
                <div class="primary-education-bangladesh">
                  {{ donationSummery?.ngo_name }}
                </div>
                <div class="img_">
                  <img
                    class="arrow-right-alt-icon"
                    loading="lazy"
                    alt=""
                    src="../../../../../assets/images/icon/checkmark-badge.svg"
                  />
                </div>
              </div>
              <div class="primary-education-description">
                {{ donationSummery?.description }}
              </div>
              <div class="donation_count">
                <div class="img_number">
                  <div class="img_">
                    <img
                      class="arrow-right-alt-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/user-multiple.svg"
                    />
                  </div>
                  <div class="number">
                    {{ donationSummery?.total_donors | number }}
                  </div>
                </div>

                <div class="img_number">
                  <div class="img_">
                    <img
                      class="arrow-right-alt-icon"
                      loading="lazy"
                      alt=""
                      src="../../../../../assets/images/icon/time-01.svg"
                    />
                  </div>
                  <div class="number">22 Days Left</div>
                </div>
              </div>

              <dx-progress-bar
                class="progress"
                [min]="0"
                [max]="donationSummery?.fundraising_target"
                [value]="donationSummery.total_fund_raised"
                [showStatus]="false"
              ></dx-progress-bar>

              <div class="donation_count">
                <div class="donation_number">
                  &#2547; {{ donationSummery?.total_fund_raised | number }}
                </div>

                <div class="donation_status">
                  Raised of ৳
                  {{ donationSummery?.fundraising_target | number }} goal
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [maxWidth]="721"
  [maxHeight]="600"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="isSuccessful"
  [showTitle]="false"
>
  <div class="root">
    <div class="bookmarks">
      <img
        class="icons8-no-bookmark-100-1"
        loading="lazy"
        alt=""
        src="../../../../assets/images/icon/success-mark.svg"
      />
    </div>
    <section class="you-havent-bookmarked">Your Payment Was Successful</section>
    <div class="feedback">
      Thank you for your payment. Your transaction was completed successfully. A
      receipt has been generated for your records.
    </div>
    <!-- [routerLink]="['dashboard/form-preview']"
  [queryParams]="{ id: formIdOnParam }" -->
    <div class="bookmarks">
      <div
        class="yellow-button-lg cursor"
        [routerLink]="['/all-donation-campaigns']"
      >
        Back
      </div>
      <div class="blue-button-md cursor" (click)="downloadPopup()">
        Download Receipt
      </div>
    </div>
  </div>
</dx-popup>
<dx-popup
  [maxWidth]="1200"
  [maxHeight]="1000"
  [height]="750"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="downloadPaymentReceipt"
  [showTitle]="true"
>
  <div class="download_popup" style="padding: 2%">
    <div id="contentToConvert" class="full_download_content">
      <div class="d-flex justify-content-between">
        <div class="Logo">
          <img
            loading="lazy"
            width="200"
            height="50"
            alt=""
            src="../../../../assets/images/logo/Logo.png"
          />
        </div>
        <div class="d-flex gap-2">
          <div class="invoice">Payment Receipt</div>
          <div class="blue_color"></div>
        </div>
      </div>
      <div class="invoice mb-3 mt-5">Invoice to:</div>
      <div class="d-flex justify-content-between common_font mb-4">
        <div>{{ totalDonationModel.name }}</div>
        <div>
          Date:
          <span style="margin-left: 12px">
            {{ currentDate | date : "fullDate" }}</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between common_font mb-4">
        <div>Email: {{ totalDonationModel.email }}</div>
        <div class="d-flex justify-content-between">
          <div>Payment Method:</div>
          <div class="card_payment">Card</div>
        </div>
      </div>
      <div class="invoice mb-3" style="color: #0054a6">Campaigns</div>
      <div class="datagrid_container">
        <dx-data-grid
          class="donation_list_datagrid"
          id="gridContainer"
          [dataSource]="campaigns"
          [allowColumnReordering]="true"
          [allowColumnResizing]="true"
          [wordWrapEnabled]="true"
          [columnAutoWidth]="false"
          [rowAlternationEnabled]="true"
          rowTemplate="rowTemplateName"
          [showBorders]="true"
        >
          <dxo-scrolling mode="virtual" useNative="false"></dxo-scrolling>
          <dxi-column
            caption="Organization"
            dataField="oorganization"
            alignment="left"
            [width]="250"
          ></dxi-column>
          <dxi-column
            caption="Campaign"
            dataField="campaign"
            alignment="left"
            [width]="650"
          ></dxi-column>
          <dxi-column
            caption="Amount"
            dataField="amount"
            alignment="left"
            [width]="200"
          ></dxi-column>
          <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
            <tr class="custom-row">
              <td class="ngo_name">
                {{ this.donationSummery?.ngo_name }}
              </td>
              <td class="ngo_name">
                {{ donationSummery?.title }}
              </td>
              <td style="padding-left: 14px; color: #272727">
                <div class="amount_download">
                  <img
                    width="25"
                    height="15"
                    alt=""
                    src="../../../../assets/images/icon/bd_tk_black.png"
                  />
                  {{ totalDonationModel?.amount }}
                </div>
              </td>
            </tr>
          </tbody>
        </dx-data-grid>
      </div>
      <div>
        <div
          class="d-flex justify-content-end mt-5"
          style="gap: 107px; margin: 0 19px; font-size: 18px; font-weight: 700"
        >
          <div>Total</div>
          <div style="color: #0054a6">
            <img
            width="25"
            height="18"
            alt=""
            src="../../../../assets/images/icon/bd_tk_blue.png"
          />
             {{ totalDonationModel?.amount }}
          </div>
        </div>
        <div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2 download">
      <button class="blue-button-lg w-30" (click)="downloadPDF()">
        Download
      </button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="termsPopup"
  [minWidth]="'30vw'"
  [minHeight]="620"
  [maxWidth]="'80vw'"
  [maxHeight]="'90vh'"
  [showTitle]="true"
  title="Terms of Service"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <app-terms-and-conditions [popup]="true"></app-terms-and-conditions>
</dx-popup>

<dx-popup
  [(visible)]="privacyPopup"
  [minWidth]="'30vw'"
  [minHeight]="620"
  [maxWidth]="'80vw'"
  [maxHeight]="'90vh'"
  [showTitle]="true"
  title="Privacy Policy"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <app-privacy-policy [popup]="true"></app-privacy-policy>
</dx-popup>

<dx-popup
  [(visible)]="pricingPopup"
  [minWidth]="'30vw'"
  [minHeight]="620"
  [maxWidth]="'80vw'"
  [maxHeight]="'90vh'"
  [showTitle]="true"
  title="Pricing Policy"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <app-pricing [popup]="true"></app-pricing>
</dx-popup>