<div class="root">
  <div class="title_link">
    <div class="top">
      <span class="cursor" style="color: #0054a6">Home > </span
      ><span class="all_campaign cursor"> All Campaigns</span>
    </div>
    <div class="news-container">
      <h1 class="stay-updated-with">Campaigns we care about</h1>
      <div class="empowering-ngos-in">
        Join hands to fund these causes and bring positive change.
      </div>
      <div class="search_bar" style="padding-right: 0px">
        <dx-autocomplete
          class="search_box"
          [dataSource]="allCampaignTitles"
          valueExpr="title"
          [(value)]="searchText"
          placeholder="Search Campaign"
          [searchExpr]="['title', 'subtitle']"
          [searchTimeout]="10"
          [showClearButton]="true"
          [wrapItemText]="true"
          (onEnterKey)="searchCampaign($event)"
          (onSelectionChanged)="selectCampaign($event)"
        >
          <dxi-button
            name="searchIcon"
            location="before"
            [options]="searchIcon"
          ></dxi-button>

          <div id="campaign_titles" *dxTemplate="let data of 'item'">
            <div style="font-size: 16px; font-weight: 500">
              {{ data.title }}
            </div>
            <div>{{ data.subtitle }}</div>
          </div>

          <dxi-button
            *ngIf="searchText?.length > 0"
            name="crossIcon"
            location="after"
            [options]="crossIcon"
          ></dxi-button>
        </dx-autocomplete>
      </div>
    </div>
  </div>
  <section class="news-cards">
    <div
      *ngFor="let article of campaignList"
      class="cards"
      (click)="navigateToCampaign(article)"
    >
      <div
        class="rectangle-parent"
        [style]="'background-image: url(' + article.cover_img_vdo.url + ');'"
      >
        <div class="article-wrapper" *ngIf="article.is_featured">
          <div class="article">Featured</div>
        </div>
      </div>
      <div class="development-ngos-arm">
        {{ article?.title }}
      </div>
      <div class="name_with_checkMark">
        <div class="primary-education-bangladesh">
          {{ article?.ngo_name }}
        </div>
        <div class="img">
          <img
            class="arrow-right-alt-icon"
            loading="lazy"
            alt=""
            src="../../../../../assets/images/icon/checkmark-badge.svg"
          />
        </div>
      </div>
      <div class="primary-education-description">
        {{ article?.description }}
      </div>
      <div class="donation_count">
        <div class="img_number">
          <div class="img">
            <img
              class="arrow-right-alt-icon"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/user-multiple.svg"
            />
          </div>
          <div class="number">
            {{ article?.total_donors ? (article?.total_donors | number) : 0 }}
          </div>
        </div>

        <div class="img_number">
          <div class="img">
            <img
              class="arrow-right-alt-icon"
              loading="lazy"
              alt=""
              src="../../../../../assets/images/icon/time-01.svg"
            />
          </div>
          <div class="number">
            {{
              article?.end_date
                ? calculateDaysLeft(article.end_date) + " Days Left"
                : "Ongoing"
            }}
          </div>
        </div>
      </div>

      <dx-progress-bar
        class="progress"
        [min]="0"
        [max]="article.fundraising_target"
        [value]="article.total_fund_raised || 0"
        [showStatus]="false"
      ></dx-progress-bar>

      <div class="donation_count">
        <div class="donation_number">
          &#2547;
          {{
            article?.total_fund_raised
              ? (article?.total_fund_raised | number)
              : 0
          }}
        </div>

        <div class="donation_status">
          Raised of ৳ {{ article.fundraising_target | number }} goal
        </div>
      </div>

      <dx-button
        class="yellow-button-md"
        (click)="navigateToCampaignDonation(article)"
        >Donate Now
      </dx-button>
    </div>
  </section>
  <div class="direction-button">
    <dx-button
      class="blue-button-md"
      (click)="getAllCampaigns(page + 1, 3)"
      [disabled]="isShowMoreDisabled"
      >Load More</dx-button
    >
  </div>
</div>
