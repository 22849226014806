import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsArticleTypeEnum } from 'src/app/shared/enum/news-article.enum';
import { UrlEnum } from 'src/app/shared/enum/url.enum';
import { NewsArticleBodyModel } from 'src/app/shared/model/article.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-article-news',
  templateUrl: './article-news.component.html',
  styleUrl: './article-news.component.scss',
})
export class ArticleNewsComponent implements OnInit {
  public commentSortingType: string[] = ['Newest', 'Oldest'];
  public isButtonDisabled: boolean = true;
  public autoResizeEnabled: boolean = true;

  public newsArticleId: string;
  public newsArticle: NewsArticleBodyModel = new NewsArticleBodyModel();
  public newsArticleTypeEnum = NewsArticleTypeEnum;
  public activeSectionId: number = 1;

  private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilitiesService: UtilitiesService,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.newsArticleId = decryptedParams.id;
        if (this.newsArticleId) {
          this.loadArticleById(this.newsArticleId);
        }
        this.loadArticleNews();
      }
    });
  }

  private loadArticleById(articleId: string): void {
    this.utilitiesService.showSpinner(true);
    this.shohayBlogService.getArticleById(articleId).subscribe({
      next: (result: any) => {
        this.utilitiesService.showSpinner(false);
        this.onSuccess(result);
      },
      error: (error) => {
        this.utilitiesService.showSpinner(false);
        this.onError(error);
      },
    });
  }

  private onSuccess(result: any) {
    this.newsArticle = new NewsArticleBodyModel(result);
    if (this.newsArticle.blog_type === this.newsArticleTypeEnum.NEWS) {
      this.newsArticle.table_of_contents = [
        { content_order: 1, description: this.newsArticle.content },
      ];
    }
  }

  private onError(error: any) {
    this.utilitiesService.showSwalWithToast(
      'ERROR',
      error?.error?.message,
      'error',
      false,
      3000
    );
  }

  public getDateTime(publishedDate: Date): string {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(publishedDate));
  }

  private loadArticleNews(): void {
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      this.articleNewsOriginalList = result.data.splice(0, 4);
      this.articleNewsList = JSON.parse(
        JSON.stringify(this.articleNewsOriginalList)
      );
    });
  }

  public onChange(contentID: number) {
    this.activeSectionId = contentID;
    this.scrollToSection(contentID);
  }

  public scrollToSection(contentID: number) {
    this.activeSectionId = contentID;
    const element = document.getElementById(contentID.toString());

    if (element) {
      const offsetPosition =
        element.getBoundingClientRect().top + window.scrollY - 75;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  //progressbar
  public getScrollProgress(): number {
    const scrollPosition = window.scrollY;
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    return (scrollPosition / totalHeight) * 100;
  }
}
