<div class="root">
  <div class="root-child">
    <div class="difference">
      <h1 class="ready-to-make">
        {{ "Contact-form.ready-to-make" | translate }}
      </h1>
      <div class="have-an-ngo">
        {{ "Contact-form.have-an-ngo" | translate }}
      </div>

      <ngb-carousel class="contact-form" style="width: 100%">
        <ng-template ngbSlide *ngFor="let slide of ngoSlide; let i = index">
          <div class="story">
            <div class="rectangle-parent">
              <div class="expertise-content-parent">
                <div class="expertise-content">
                  <!-- class="logo-1-icon" -->
                  <img
                    loading="lazy"
                    alt=""
                    [ngSrc]="ngoSlide[i].company_logo"
                    width="111"
                    height="32"
                  />
                </div>
                <div class="shohays-expertise-enabled">
                  <!-- {{ "Testimonials.shohays" | translate }} -->
                  {{ ngoSlide[i].description }}
                </div>
              </div>
              <div class="testimonial">
                <div class="testimonial-content">
                  <div class="img-wrapper">
                    <!-- class="testimonial-image-icon" -->
                    <img
                      loading="lazy"
                      alt=""
                      [ngSrc]="ngoSlide[i].image"
                      height="64"
                      width="64"
                    />
                  </div>

                  <div class="testimonial-details">
                    <div class="testimonial-name">
                      <b class="ngo-representative-name">
                        <!-- {{
                        "Testimonials.NGO-Representative" | translate
                      }} -->

                        {{ ngoSlide[i].representative_name }}
                      </b>
                      <div class="position-or-title">
                        <!-- {{ "Testimonials.Position" | translate }} -->

                        {{ ngoSlide[i].title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
      <div class="indicator_div" *ngIf="ngoSlide.length">
        <div class="see_all">
          {{ "slider.see-more" | translate }}
        </div>
      </div>
    </div>
    <div class="ngos">
      <div class="buttons">
        <button
          [ngClass]="
            contactForm.contact_type === contactType.MESSAGE
              ? 'blue-button-rounded-md'
              : 'white-button-rounded-md'
          "
          (click)="changeContactType(contactType.MESSAGE)"
        >
          {{ "Contact-form.Message" | translate }}
        </button>
        <button
          [ngClass]="
            contactForm.contact_type === contactType.CALL
              ? 'blue-button-rounded-md'
              : 'white-button-rounded-md'
          "
          (click)="changeContactType(contactType.CALL)"
        >
          {{ "Contact-form.Book" | translate }}
        </button>
      </div>

      <div class="form_div">
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.Name" | translate
          }}</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="contactForm.name"
            [placeholder]="'Contact-form.placeHolder1' | translate"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Name is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.Email" | translate
          }}</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="contactForm.email"
            [placeholder]="'Contact-form.placeHolder2' | translate"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Email is required"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="email"
                message="Invalid email. e.g. john@gmail.com"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.Organization" | translate
          }}</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="contactForm.organization"
            [placeholder]="'Contact-form.placeHolder4' | translate"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Organization is required"
              ></dxi-validation-rule
            ></dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.Messages" | translate
          }}</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="contactForm.message"
            [placeholder]="'Contact-form.placeHolder5' | translate"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Message is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        @if(contactForm.contact_type === contactType.CALL) {
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.Phone" | translate
          }}</label>
          <dx-text-box
            class="input_field"
            [(ngModel)]="contactForm.phone"
            [placeholder]="'Contact-form.placeHolder3' | translate"
            [showClearButton]="true"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Phone number is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.DateTime" | translate
          }}</label>
          <dx-date-box
            class="input_field"
            [(ngModel)]="contactForm.date"
            [placeholder]="'Contact-form.placeHolder6' | translate"
            [showClearButton]="true"
            type="datetime"
            [min]="today"
            displayFormat="longDateLongTime"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="Date & Time is required"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
        }
        <div class="input_div">
          <label class="field_label requiredMark">{{
            "Contact-form.select-one-of" | translate
          }}</label>
          <dx-radio-group
            class="input_field radio_group_field"
            [items]="[
              {
                name: 'Contact-form.Planning' | translate,
                value: ngoStatus.PLANNING
              },
              {
                name: 'Contact-form.already-have-an' | translate,
                value: ngoStatus.HAVE
              }
            ]"
            displayExpr="name"
            valueExpr="value"
            [(value)]="contactForm.ngo_status"
            layout="vertical"
          >
            <dx-validator validationGroup="validationGrp">
              <dxi-validation-rule
                type="required"
                message="You must select one."
              ></dxi-validation-rule
            ></dx-validator>
          </dx-radio-group>
        </div>
      </div>

      <button class="blue-button-md" (click)="submit()">
        {{ "Contact-form.Submit" | translate }}
      </button>
    </div>
  </div>
</div>
