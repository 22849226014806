import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
})
export class GlobalSearchComponent implements OnInit {
  public searchValue: string = '';
  public pagination: any;

  public searchResults: any[];
  public articleList: any[];

  constructor(
    private readonly router: Router,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getArticleList(1);
  }

  private getArticleList(pageNo: number): void {
    this.shohayBlogService.getArticleNews(pageNo, 12).subscribe({
      next: (result) => {
        this.articleList = result.data;
        this.searchResults = result.data;
        this.pagination = result.pagination;
        console.log(result);
      },
    });
  }

  public onSearchClick(): void {
    if (this.searchValue?.length > 0) {
      this.shohayBlogService.searchArticleNews(this.searchValue).subscribe({
        next: (result) => {
          this.searchResults = result.data;
          console.log(result);
        },
      });
    } else {
      this.searchResults = this.articleList;
    }
  }

  public goToArticleNews(id: number, newsArticleId: string): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: id,
    });
    this.router.navigate(['/news-article'], {
      queryParams: { data: encryptedParams },
    });
  }

  public loadNextPage(): void {
    this.getArticleList(this.pagination.page + 1);
  }
}
