import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApprovalStatusEnum } from 'src/app/shared/enum/approval-category.enum';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-all-donation-campaign',
  templateUrl: './all-donation-campaign.component.html',
  styleUrl: './all-donation-campaign.component.scss',
})
export class AllDonationCampaignComponent {
  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/search_icon.svg',
    stylingMode: 'text',
    hint: 'Search',
    onClick: () => {
      this.searchCampaign({});
    },
  };

  public crossIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/cross-icon.svg',
    stylingMode: 'text',
    hint: 'Clear',
    onClick: () => {
      this.searchText = null;
      this.getAllCampaigns(1, 6);
    },
  };

  public size: number = 6;
  public page: number = 1;
  private totalCampaigns: number = 0;
  public isShowMoreDisabled: boolean = false;

  public searchText: string;

  public allCampaignTitles: any[] = [];
  public campaignList: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly ngoCampaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getAllCampaigns(1, 6);
    this.getAllNgoDonationCampaignTitles();
  }

  public getAllCampaigns(page: number, size: number): void {
    this.page = page;
    this.size = size;

    this.ngoCampaignService.getAllNgoDonationCampaign(page, size).subscribe({
      next: (result: any) => {
        result.data = result.data.filter(
          (campaign) =>
            campaign.approval_status === ApprovalStatusEnum.ACCEPTED ||
            campaign.approval_status === null
        );
        this.onSuccess(result);
      },
    });
  }

  private onSuccess(res: any) {
    if (this.page === 1) {
      this.campaignList = res.data;
      this.page = this.page + 1;
    } else {
      this.campaignList.push(...res.data);
    }
    this.totalCampaigns = res.total;
    if (this.totalCampaigns <= this.campaignList.length) {
      this.isShowMoreDisabled = true;
    }
  }

  private onError(errorMessage: string, title: string): void {
    this.spinner.hide();
    this.utilitiesService.showSwalWithToast(
      `${title}`,
      `${errorMessage} , Something went wrong `,
      'error'
    );
  }

  private getAllNgoDonationCampaignTitles(): void {
    this.ngoCampaignService.getAllNgoDonationCampaignTitles().subscribe({
      next: (result: any) => {
        this.allCampaignTitles = result;
      },
      error: (error: any) => {
        this.onError(`${error.message}`, `${error.name}`);
      },
    });
  }

  public selectCampaign(e: any): void {
    if (e.selectedItem) {
      const campaign = e.selectedItem;
      const encryptedParams = this.localStorageService.encryptObjectUrl({
        ngo_id: campaign.ngo_id,
        project_id: campaign.project_id,
        id: campaign.id,
      });
      this.router.navigate(['/campaign'], {
        queryParams: { data: encryptedParams },
      });
    }
  }

  public async searchCampaign(e: any) {
    if (this.searchText) {
      const idsArray = await this.getSearchedCampaignIds(this.searchText);

      this.ngoCampaignService
        .getNgoCampaignsByIds(idsArray)
        .subscribe((result: any) => {
          this.isShowMoreDisabled = true;
          this.campaignList = result;
        });
    } else {
      this.getAllCampaigns(1, 6);
    }
  }

  public refreshSearchBar(e: any): void {
    if (e.value === null) {
      this.getAllCampaigns(1, 6);
    }
  }

  private getSearchedCampaignIds(text: string): string[] {
    const lowerText = text.toLowerCase();
    return this.allCampaignTitles
      .filter((item) => item.title_subtitle.includes(lowerText))
      .map((item) => item.id);
  }

  public calculateDaysLeft(endDate: string): number {
    const end = new Date(endDate).getTime();
    const now = new Date().getTime();
    if (end >= now) {
      return Math.ceil((end - now) / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }

  public navigateToCampaign(article: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      ngo_id: article?.ngo_id,
      project_id: article?.project_id,
      id: article?.id,
    });
    this.router.navigate(['/campaign'], {
      queryParams: { data: encryptedParams },
    });
  }

  public navigateToCampaignDonation(campaign: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/donate-to-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }
  // public showMore(): void {
  //     this.page++;
  //     this.size = 3;
  //     this.getAllCampaigns(this.page, this.size);
  // }
}
