import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { delay, Observable, of, Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { I18nServiceService } from 'src/app/shared/shared-services/i18n-service/i18n-service.service';

@Component({
  selector: 'app-hp1-hero',
  templateUrl: './hp1-hero.component.html',
  styleUrls: ['./hp1-hero.component.css'],
})
export class Hp1HeroComponent implements OnInit {
  ngoSearchQuery: string = '';
  public searchButton: any;
  private langChangeSubscription: Subscription;

  // public ngo: any = {
  //   id: 'xH_JAZABhsMzeXXn_lEv',
  //   name: 'BRAC',
  //   address: 'Dhaka',
  //   reg_no: 2,
  //   reg_at: '1981-04-22T08:00:00.000Z',
  //   renewed_at: '2015-05-15T07:00:00.000Z',
  //   valid_till: '2020-05-15T07:00:00.000Z',
  //   topic: null,
  //   website: 'brac.net',
  //   district: 'Dhaka',
  //   country: 'Bangladesh',
  //   remarks: '',
  //   verified_at: '2024-08-04T21:25:32.802Z',
  //   is_verified: false,
  //   is_featured: false,
  // };

  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.langChangeSubscription = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.updateSearchIcon();
      }
    );
    this.updateSearchIcon();
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const navbarElement = document.getElementById('hero') as HTMLElement;

    if (scrollPosition > 30) {
      navbarElement.classList.add('add-margin');
    } else {
      navbarElement.classList.remove('add-margin');
    }
  }

  public onSearchClick(): void {
    if (!this.ngoSearchQuery) {
      // return;
      this.router.navigateByUrl('/search-ngo?query=' + this.ngoSearchQuery);
    } else {
      this.router.navigateByUrl('/search-ngo?query=' + this.ngoSearchQuery);
    }
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };

  private updateSearchIcon(): void {
    this.searchButton = {
      text: this.translate.instant('Hero.Search'),
      stylingMode: 'text',
      onClick: () => {
        this.onSearchClick();
      },
    };
  }

  private scrollInterval: any;

  // Method to start scrolling until target text is visible
  scrollToText(targetText: string): void {
    const checkVisibility = () => {
      const element = document.getElementById('targetText');
      if (element && this.isElementInViewport(element)) {
        clearInterval(this.scrollInterval); // Stop scrolling when text is found
      } else {
        window.scrollTo({
          top: window.scrollY + 200, // Adjust the scroll distance per increment
          behavior: 'smooth',
        });
      }
    };

    // Start scrolling in intervals to find the text
    this.scrollInterval = setInterval(checkVisibility, 200);
  }

  private isElementInViewport(el: HTMLElement): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public goToNgoPage(): void {
    // const queryParams = { name: JSON.stringify(ngo) };
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: 'xH_JAZABhsMzeXXn_lEv',
    });
    this.router.navigate(['/ngo'], {
      queryParams: { data: encryptedParams },
    });
  }
}
