<div class="body">
  <div class="top-route">
    Home > NGO's >
    <span style="color: black">{{
      ngo?.name ? ngo?.name : "Not Available!"
    }}</span>
  </div>
  <div>
    <div class="main_body" >
      <div class="ngo-body">
        <div class="ngo-img-div">
          <div class="ngo-img">
            <img
              style="cursor: pointer"
              [src]="ngo?.logo_url ? ngo?.logo_url : './assets/group-39512.svg'"
              alt="NGO Logo"
            />
          </div>
          <div class="description-div">
            <div class="ngo-name">
              {{ ngo?.name ? ngo?.name : "Not Available!" }}
            </div>
            <div *ngIf="ngo?.is_verified" class="verified">
              <img
                class="verified-logo"
                src="./assets/images/icon/verified_icon.svg"
              />
              Verified NGO in Bangladesh and International
            </div>

            <div class="ngo-description">
              {{ ngo?.remarks ? ngo?.remarks : "No Remarks" }}
              <!-- "BRAC is an international development organization founded in Bangladesh in 1972 that partners with over 100 million people living with inequality and poverty to create opportunities to realize human potential." -->
            </div>
          </div>
        </div>

        <div class="contacts-div">
          <div class="contacts-line-div">
            <div class="contacts">
              <img src="./assets/images/icon/location_icon.svg" />
              {{ ngo?.address ? ngo?.address : "No address available!" }}
              <!-- "BRAC Centre, 75, Mohakhali C/A, Dhaka, Bangladesh" -->
            </div>
            <div class="contacts">
              <img src="./assets/images/icon/calling_icon.svg" />
              {{ ngo?.phone ? ngo?.phone : "No phone no available!" }}
              <!-- 88 02 2222 81265 -->
            </div>
          </div>
          <div class="contacts-line-div">
            <div class="contacts">
              <img src="./assets/images/icon/mail_icon.svg" />
              {{ ngo?.email ? ngo?.email : "No email available!" }}
              <!-- info@brac.net -->
            </div>
            <div class="contacts">
              <img src="./assets/images/icon/web_icon.svg" />
              {{ ngo?.website ? ngo?.website : "No website available!" }}
              <!-- info@brac.net -->
            </div>
          </div>
        </div>

        <div class="history">
          {{ ngo?.mission_vision ? ngo?.mission_vision : "No Text available!" }}
          <!-- "In the aftermath of a brutal war for independence and a devastating cyclone that ravaged his home country of Bangladesh, Sir Fazle HasanAbed, an executive working at a multinational oil company, was ompelled to do something to help. He founded BRAC, which began as a small relief effort in 1972. Sir Fazle left his job as the organization took shape, and he never looked back. For over 50 years, BRAC evolved under Sir Fazle’s leadership and vision, changing countless lives in his home country and around the world. Today, BRAC’s programs in education, health care, youth empowerment, financial inclusion, agriculture, and more reach 100 million people across Asia and Africa." -->
        </div>

        <div class="artice-news">Latest Article/News</div>

        <div
          *ngFor="let articleNews of articleNewsList"
          class="article-news-list cursor"
          (click)="
            goToArticleNews(articleNews?.id, articleNews?.news_article_id)
          "
        >
          <p class="article-name">
            {{ articleNews?.title }}
          </p>
          <p class="date">
            {{ formatDate(articleNews?.updatedAt) }}
          </p>
        </div>

        <button class="blue-button-md" routerLink="/all-news-articles">
          Read More in Article and News
        </button>

        <div style="margin-block: 20px">
          <img [src]="ngo?.ngo_profile_img_url" alt="NGO Profile Photo" />
          <!-- ./assets/images/brac/brac_1.png -->
        </div>
        <div *ngIf="ngo?.name === 'BRAC' && ngo?.ngo_profile_img_url">
          BRAC founder Sir Fazle Hasan Abed, Photo collected form
          <span
            style="color: #0056a6; cursor: pointer; text-decoration: underline"
            >BRAC</span
          >
        </div>
      </div>

      <div class="ngo-card">
        <div style="margin-bottom: 20px; font-size: 16px; font-weight: normal">
          <img
            style="cursor: pointer"
            [src]="ngo?.logoUrl ? ngo?.logoUrl : './assets/group-39512.svg'"
            alt="NGO Logo"
          />
          <!-- ./assets/images/logo/BRAC_logo 2.svg -->
        </div>

        {{ ngo?.name ? ngo?.name : "Not available!" }}

        <div class="card-info" style="margin-top: 20px">
          <span>Founded</span>
          <span>{{
            ngo?.founding_year ? ngo?.founding_year : "Not available!"
          }}</span>
        </div>
        <div class="card-info">
          <span>Team Size</span>
          <span>{{
            ngo?.no_of_employees ? ngo?.no_of_employees : "Not available!"
          }}</span>
        </div>
        <div class="card-info">
          <span>Location</span>
          <span>{{ ngo?.country ? ngo?.country : "Dhaka, Bangladesh" }}</span>
        </div>

        <div class="social-media col-sm-6">
          <img
            style="cursor: pointer"
            src="./assets/images/logo/facebook_logo.svg"
          />
          <img
            style="cursor: pointer"
            src="./assets/images/logo/twitter_logo.svg"
          />
          <img
            style="cursor: pointer"
            src="./assets/images/logo/youtube_logo.svg"
          />
          <img
            style="cursor: pointer"
            src="./assets/images/logo/linkedin_logo.svg"
          />
        </div>

        <div class="button-div">
          <!-- <button class="yellow-button-md">Donate</button> -->
          <button class="blue-button-md">Learn More</button>
        </div>
      </div>
    </div>
  </div>
</div>
