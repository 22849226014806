<div class="root" *ngIf="featuredCampaigns.length">
  <ngb-carousel style="width: 100%">
    <ng-template ngbSlide *ngFor="let slide of noOfSlides; let i = index">
      <div class="slide_div">
        <div class="inner_half_slide">
          <div
            class="info_div cursor"
            (click)="navigateToCampaign(featuredCampaigns[i])"
          >
            <div
              class="left_side"
              [ngStyle]="{
                'background-image':
                  'url(' + featuredCampaigns[i]?.cover_img_vdo?.url + ')'
              }"
            >
              <div class="yellow_title_div">
                <div class="yellow_title">Donate</div>
              </div>
            </div>
            <div class="right_side">
              <div class="ngo_name">
                {{ featuredCampaigns[i]?.ngo_name }}
              </div>
              <div class="campaign_title">
                {{ featuredCampaigns[i]?.title }}
              </div>
              <div>
                <dx-progress-bar
                  class="progress_bar"
                  [min]="0"
                  [max]="featuredCampaigns[i]?.fundraising_target"
                  [value]="featuredCampaigns[i]?.total_fund_raised || 0"
                  [showStatus]="false"
                ></dx-progress-bar>
              </div>
              <div class="campaign_target">
                ৳{{ featuredCampaigns[i]?.fundraising_target }}
              </div>
            </div>
          </div>
        </div>
        <div class="inner_half_slide">
          <div
            class="info_div cursor"
            (click)="navigateToCampaign(featuredCampaigns[i + 1])"
          >
            <div
              class="left_side"
              [ngStyle]="{
                'background-image':
                  'url(' + featuredCampaigns[i + 1]?.cover_img_vdo?.url + ')'
              }"
            >
              <div class="yellow_title_div">
                <div class="yellow_title">Donate</div>
              </div>
            </div>
            <div class="right_side">
              <div class="ngo_name">
                {{ featuredCampaigns[i + 1]?.ngo_name }}
              </div>
              <div class="campaign_title">
                {{ featuredCampaigns[i + 1]?.title }}
              </div>
              <div>
                <dx-progress-bar
                  class="progress_bar"
                  [min]="0"
                  [max]="featuredCampaigns[i + 1]?.fundraising_target"
                  [value]="featuredCampaigns[i + 1]?.total_fund_raised || 0"
                  [showStatus]="false"
                ></dx-progress-bar>
              </div>
              <div class="campaign_target">
                ৳{{ featuredCampaigns[i + 1]?.fundraising_target }}
              </div>
            </div>
          </div>
        </div>
        <div class="inner_half_slide">
          <div
            class="info_div cursor"
            (click)="navigateToCampaign(featuredCampaigns[i + 2])"
          >
            <div
              class="left_side"
              [ngStyle]="{
                'background-image':
                  'url(' + featuredCampaigns[i + 2]?.cover_img_vdo?.url + ')'
              }"
            >
              <div class="yellow_title_div">
                <div class="yellow_title">Donate</div>
              </div>
            </div>
            <div class="right_side">
              <div class="ngo_name">
                {{ featuredCampaigns[i + 2]?.ngo_name }}
              </div>
              <div class="campaign_title">
                {{ featuredCampaigns[i + 2]?.title }}
              </div>
              <div>
                <dx-progress-bar
                  class="progress_bar"
                  [min]="0"
                  [max]="featuredCampaigns[i + 2]?.fundraising_target"
                  [value]="featuredCampaigns[i + 2]?.total_fund_raised || 0"
                  [showStatus]="false"
                ></dx-progress-bar>
              </div>
              <div class="campaign_target">
                ৳{{ featuredCampaigns[i + 2]?.fundraising_target }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>

  <div class="indicator_div" *ngIf="featuredCampaigns.length">
    <div class="see_all" routerLink="/all-donation-campaigns">
      {{ "slider.see-more" | translate }}
    </div>
  </div>
</div>
