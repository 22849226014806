import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-ngo-page',
  templateUrl: './ngo-page.component.html',
  styleUrls: ['./ngo-page.component.css'],
})
export class NgoPageComponent implements OnInit {
  private elastic_id: string = null;
  private ngoParams: any;
  public ngo: any = null;
  public articleNewsList: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly ngoService: NgoService,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   const value = params['ngo'];
    //   if (value) {
    //     this.ngoParams = JSON.parse(params['ngo']);

    //     if (this.ngoParams) {
    //       this.elastic_id = this.ngoParams.id;
    //       this.getNgo();
    //     }
    //   }
    //   this.getArticleNews();
    // });

    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.elastic_id = decryptedParams.id;
        this.getNgo();
      } else {
        this.router.navigateByUrl('/');
        console.error('Decryption failed');
      }
    });
  }

  private getNgo(): void {
    this.ngoService
      .getNgoByElasticId(this.elastic_id)
      .subscribe((response: any) => {
        if (response) {
          this.ngo = response;
        } else {
          this.ngo = this.ngoParams;
        }
      });
  }

  private getArticleNews(): void {
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      this.articleNewsList = result.data.splice(0, 5);
    });
  }

  public goToArticleNews(id: number, newsArticleId: string): void {
    this.router.navigate(['/news-article'], {
      queryParams: { newsArticleId: newsArticleId, id: id },
    });
  }

  public formatDate(date: string): string {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date));
  }
}
