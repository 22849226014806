import { ContactTypeEnum, NgoStatusEnum } from '../enum/call-booking.enum';

export class CallBookingModel {
  name: string = null;
  email: string = null;
  organization: string = null;
  message: string = null;
  phone: string = null;
  date: Date = null;
  contact_type: ContactTypeEnum.MESSAGE | ContactTypeEnum.CALL = ContactTypeEnum.MESSAGE;
  ngo_status: NgoStatusEnum.PLANNING | NgoStatusEnum.HAVE = NgoStatusEnum.PLANNING;

  constructor(){}
}
