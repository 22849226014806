import { Component } from '@angular/core';
import {
  ContactTypeEnum,
  NgoStatusEnum,
} from 'src/app/shared/enum/call-booking.enum';
import { CallBookingModel } from 'src/app/shared/model/call-booking.model';
import { ContactRequestService } from 'src/app/shared/services/contact-request/contact-request.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import * as L from 'leaflet';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent {
  public today: Date = new Date();
  public contactType = ContactTypeEnum;
  public ngoStatus = NgoStatusEnum;
  public contactForm: CallBookingModel = new CallBookingModel();
  private map!: L.Map;

  private customIcon = L.icon({
    iconUrl: 'assets/images/icon/map_red_icon.svg',
    // shadowUrl: 'assets/images/icon/map-pin.svg',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly contactRequestService: ContactRequestService
  ) {}

  ngOnInit(): void {
    this.initMap();
  }

  public changeContactType(contactType: ContactTypeEnum): void {
    this.contactForm.contact_type = contactType;
  }

  public submit(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.contactRequestService.bookACall(this.contactForm).subscribe({
        next: (result: any) => {
          this.utilitiesService.showSwalWithToast(
            'Success',
            `${
              this.contactForm.contact_type === this.contactType.CALL
                ? 'Your call is booked'
                : 'Message send'
            } successfully.`
          );
          ValidationEngine.resetGroup('validationGrp');
        },
      });
    }
  }

  private initMap(): void {
    this.map = L.map('map').setView([23.732348, 90.417992], 16);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution: '© OpenStreetMap contributors',
    }).addTo(this.map);

    const marker = L.marker([23.73092339204098, 90.41192059446477], {
      icon: this.customIcon,
    }).addTo(this.map);
    // 23.73092339204098, 90.41192059446477
    marker
      .bindPopup('53, Purana Paltan (2nd Floor), Dhaka-1000, Bangladesh')
      .openPopup();

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: 'shohay.net',
    }).addTo(this.map);
  }
}
